import {
    Button,
    CircularProgress,
    Grid2,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import ReadDOM from "react-dom/client";
import { post } from "./functions";

const Application: React.FC = () => {
    const [email, setEmail] = React.useState("");
    const [token, setToken] = React.useState("");
    const [error, setError] = React.useState(false);
    const [showToken, setShowToken] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const getToken = async () => {
        try {
            setLoading(true);
            await post("/login/", { email }, "POST");
            setShowToken(true);
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const login = async () => {
        try {
            setLoading(true);
            await post("/login/", { email, token }, "PUT");
            window.location.href = "/";
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Grid2
            container
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            flexGrow={1}
        >
            <Grid2 sx={{ mb: 4 }}>
                <img src={"/static/img/redesign.svg"} width={400} />
            </Grid2>

            {!showToken && (
                <>
                    <Grid2 sx={{ mb: 4 }}>
                        <TextField
                            label={"Email"}
                            sx={{ width: 400 }}
                            error={error}
                            autoFocus
                            onKeyUp={(event) => {
                                if (event.key === "Enter") {
                                    getToken();
                                }
                            }}
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                        />
                    </Grid2>
                    <Grid2 sx={{ mb: 4 }}>
                        {loading ? (
                            <Button variant={"contained"} sx={{ width: 400 }}>
                                <CircularProgress size={24} color="inherit" />
                            </Button>
                        ) : (
                            <Button
                                variant={"contained"}
                                sx={{ width: 400 }}
                                onClick={getToken}
                            >
                                GET VERTIFICATION TOKEN
                            </Button>
                        )}
                    </Grid2>
                </>
            )}
            {showToken && (
                <>
                    <Grid2 sx={{ mb: 4 }}>
                        <TextField
                            label={"Verification Code"}
                            sx={{ width: 400 }}
                            type="password"
                            onChange={(event) => {
                                setToken(event.target.value);
                            }}
                        />
                    </Grid2>
                    <Grid2 sx={{ mb: 2 }}>
                        <Button
                            variant={"contained"}
                            sx={{ width: 400 }}
                            onClick={login}
                        >
                            LOGIN
                        </Button>
                    </Grid2>
                    <Grid2 sx={{ mb: 2 }}>
                        <Typography variant={"caption"}>
                            Please check your email for token
                        </Typography>
                    </Grid2>
                </>
            )}

            <Grid2 sx={{ mb: 2 }}>
                <Button
                    variant="outlined"
                    size="large"
                    startIcon={
                        <img src="/static/img/microsoft.svg" width={20} />
                    }
                    sx={{ width: 400 }}
                    href={"/saml/sso/"}
                >
                    Sign in with Microsoft
                </Button>
            </Grid2>

            <Grid2>
                <Typography variant={"caption"}>
                    &#169; Copyright of Redesign Group 2024
                </Typography>
            </Grid2>
        </Grid2>
    );
};

const root = ReadDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<Application />);
